<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="serviceAnalysis-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <!-- <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
        <span>所属公司:</span>
        <el-select
          class="common-screen-input"
          v-model="params.companyId"
          @change="commonFun"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in rolesCompany"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div> -->
      <div class="common-input-container">
        <span>基准值:</span>
        <el-select
          class="common-screen-input"
          v-model="params.referenceType"
          @change="commonFun"
          placeholder="请选择"
          filterable
        >
          <el-option key="other" label="客户" value="other"> </el-option>
          <el-option key="system" label="系统" value="system"> </el-option>
        </el-select>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="
          () => {
            satisfactionList();
          }
        "
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
      <br />
      <!-- <div class="common-input-container">
        <el-date-picker
          v-model="params.datePicker"
          :clearable="false"
          @change="
            () => {
              changeDate();
            }
          "
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="common-input-container">
        <el-radio-group v-model="params.type" @change="changeRadio">
          <el-radio-button :label="1">近7天</el-radio-button>
          <el-radio-button :label="2">近30天</el-radio-button>
          <el-radio-button :label="3">近90天</el-radio-button>
          <el-radio-button :label="0">昨天</el-radio-button>
        </el-radio-group>
      </div> -->
      <div class="common-input-container">
        <common-lately-date @commonGetDate="commonGetDate" :commonRadio="commonRadio" :startDate="params.startDate" :endDate="params.endDate" ref="commonReset"></common-lately-date>
      </div>
    </div>

    <!-- <div class="common-upload-container" style="margin: 0">
      <el-button class="common-screen-btn" type="primary" :loading="btnLoading"
        >上传服务数据</el-button
      >
      <input v-if="!btnLoading"
        class="common-upload-input"
        @input="
          (e) => {
            uploadKPIXslx(e);
          }
        "
        type="file"
      />
    </div> -->
    <el-button class="common-screen-btn" @click="() => download()"
      >导出数据表格</el-button
    >
    <div id="echartsLine"></div>
    <el-table class="common-table" :data="tableData" @sort-change="tableSort">
      <el-table-column
        prop="area"
        label="分公司(地区)"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="shopName"
        label="店铺名称"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="platformName"
        label="所属平台"
        sortable="custom"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="consultationNum"
        label="总咨询量"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="piecesNumber"
        label="条数（不满、很不满）"
        :formatter="tableColumn"
        sortable="custom"
      ></el-table-column>
      <el-table-column label="满意度" sortable="custom" prop="satisfaction">
        <template slot-scope="scope">
          <span :class="scope.row.satisfactionFlag == '0' && scope.row.satisfaction ? 'warning' : ''">{{
            scope.row.satisfaction || "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="
              () => {
                $router.push({
                  path: '/data/customer-service-satisfied/source',
                  query: {
                    companyId: params.companyId,
                    shopId: scope.row.shopId,
                    startDate: params.startDate,
                    commonRadio: commonRadio,
                    endDate: params.endDate,
                    referenceType: params.referenceType,
                  },
                });
              }
            "
            >满意度数据源</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { satisfactionList, uploadKPIXslx, satisCharts } from "../../service/dataInfo.js";
import { tableColumn, DateTransform, getLatelyDay, Config } from "../../utils/index.js";
import CommonLatelyDate from "../../components/common/commonLatelyDate.vue"
import { mapState } from "vuex";

export default {
  components: { Breadcrumb, CommonLatelyDate },
  data() {
    return {
      loading: false,
      btnLoading: false,
      tableColumn,
      DateTransform,
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => { return item.id != 0 }), // 所属公司下拉
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "数据", isLink: false },
        { title: "满意度分析", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      commonRadio: 3,
      params: {
        startDate: DateTransform(getLatelyDay(90)[0]),
        endDate: DateTransform(getLatelyDay(90)[1]),
        referenceType: "other",
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      pickerOptions: {
        // 指派分公司的禁用时间范围
        // disabledDate: (time) => {
        //   return (
        //     time.getTime() <
        //       new Date(this.$route.query.startDate).getTime() - 24 * 60 * 60 * 1000 ||
        //     time.getTime() > new Date(this.$route.query.endDate).getTime()
        //   );
        // },
      },
    };
  },
  created() {
    this.getComId()

    // this.satisfactionList();
  },
  computed: {
    ...mapState(["getUserInfo","comId"])
  },
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.companyId = this.comId
        }else{
          this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.satisfactionList();
      },400)
    },
    commonGetDate (startTime, endTime, commonRadio) {
      this.params.startDate = startTime
      this.params.endDate = endTime
      this.commonRadio = commonRadio
      this.satisfactionList()
    },
    download() {
      // 导出项目KPI
      let params = { ...this.params };
      // params.startDate = DateTransform(params.datePicker[0]);
      // params.endDate = DateTransform(params.datePicker[1]);
      // delete params.datePicker;
      delete params.page;
      delete params.pageSize;
      if (!params.type) {
        delete params.type;
      }
      let url = Config.api.url + "/excel/satisfactionAnalysisExport";
      let paramArr = [];
      for (let item in params) {
        if (params[item]) {
          paramArr.push(item + "=" + params[item]);
        }
      }
      url = url + "?" + paramArr.join("&");
      window.location.href = url;
    },
    async satisCharts() {
      let params = { ...this.params };
      if (params.datePicker && params.datePicker.length) {
        params.startDate = DateTransform(params.datePicker[0]);
        params.endDate = DateTransform(params.datePicker[1]);
      }
      if (!params.type) {
        delete params.type;
      }
      if (!params.sortType && params.sortType != 0) {
        delete params.sortType;
      }
      let resData = (await satisCharts(params)).data;
      let echartsDate = [];
      let echartsData = [];
      let arr = ["条数（不满、很不满）", "满意度"];
      let arrKey = ["piecesNumber", "satisfaction"];
      resData.map((item) => {
        echartsDate.push(item.excelDate);
      });
      arr.map((arrItem, arrIndex) => {
        echartsData.push({
          data: [],
          name: arrItem,
          type: "line",
          markLine: {
            data: [],
          },
          // stack: "总量",
        });
        resData.map((item, index) => {
          let keyStr = "";
          if (arrKey[arrIndex] == "satisfaction") {
            keyStr = "satisfactionReference";
          } else {
            keyStr = "";
          }
          echartsData[arrIndex].data.push(item[arrKey[arrIndex]]);
          if (index + 2 < resData.length && keyStr) {
            echartsData[arrIndex].markLine.data.push([
              {
                symbol: "none",
                coord: [index, Number(item[keyStr])],
              },
              {
                symbol: "none",
                coord: [index + 1, Number(resData[index + 1][keyStr])],
              },
            ]);
          } else if (index + 1 < resData.length && keyStr) {
            echartsData[arrIndex].markLine.data.push([
              {
                symbol: "none",
                coord: [index, Number(item[keyStr])],
              },
              {
                symbol: "none",
                label: {
                  position: "end",
                  formatter: arrItem + " " + Number(resData[index + 1][keyStr]),
                },
                coord: [index + 1, Number(resData[index + 1][keyStr])],
              },
            ]);
          }
        });
      });
      this.echartsLine(echartsData, echartsDate);
    },
    echartsLine(echartsData, echartsDate) {
      // 折线图
      let myChart = this.$echarts.init(document.getElementById("echartsLine"));
      let option;
      if (echartsDate && echartsDate.length) {
        option = {
          title: {
            text: "满意度分析",
            x: "0",
            y: "0",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["条数（不满、很不满）", "满意度"],
          },
          grid: {
            left: "3%",
            right: "6%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: echartsDate,
          },
          yAxis: {
            type: "value",
          },
          series: echartsData,
        };
      } else {
        option = {
          title: {
            text: "暂无数据",
            x: "center",
            y: "40%",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["条数（不满、很不满）", "满意度"],
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: echartsDate,
          },
          yAxis: {
            type: "value",
          },
          series: echartsData,
        };
      }
      myChart.setOption(option);
    },
    async uploadKPIXslx(e) {
      // 上传员工表格
      this.btnLoading = true;
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (format != "xls" && format != "xlsx") {
        this.$message.error("仅支持xls、xlsx格式文件");
        e.target.value = "";
        this.btnLoading = false;
        return;
      }
      await uploadKPIXslx({ file: file });
      e.target.value = "";
      this.btnLoading = false;
      this.$message.success("上传成功");
    },
    tableSort(e) {
      // 表格项排序
      let params = { ...this.params };
      params.sortField = e.prop;
      console.log(e.prop);
      switch (e.order) {
        case "ascending":
          params.sortType = 1;
          break;
        case "descending":
          params.sortType = 2;
          break;
        default:
          params.sortType = null;
      }
      this.params = params;
      this.satisfactionList();
    },
    changeDate() {
      // 选择时间时清空近几天类型选择
      this.params.type = null;
      this.satisfactionList();
    },
    changeRadio(e) {
      let date;
      switch (e) {
        case 0:
          date = 0;
          break;
        case 1:
          date = 7;
          break;
        case 2:
          date = 30;
          break;
        case 3:
          date = 90;
          break;
      }
      this.params.datePicker = getLatelyDay(date);
      this.satisfactionList();
    },
    changeBreadcrumb(num) {
      // 面包屑点击事件
      this.nowBreadcrumb = num;
    },
    commonFun() {
      this.params.page = 1;
      this.params.pageSize = 10;
      this.satisfactionList();
    },
    handleSelectionChange(row) {
      console.log(row);
    },
    resetBtn() {
      this.commonRadio = 3
      this.params = {
        startDate: DateTransform(getLatelyDay(90)[0]),
        endDate: DateTransform(getLatelyDay(90)[1]),
        referenceType: "other",
        page: 1,
        pageSize: 10,
        companyId: this.comId
      };
      
      this.$refs.commonReset.resetFun(3, DateTransform(getLatelyDay(90)[0]), DateTransform(getLatelyDay(90)[1]))
      this.satisfactionList();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.satisfactionList();
    },
    async satisfactionList() {
      this.satisCharts();
      this.loading = true;
      let params = { ...this.params };
      if (!params.sortType && params.sortType != 0) {
        delete params.sortType;
      }
      let resData = (await satisfactionList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.list;
      this.loading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.serviceAnalysis-container {
  text-align: left;
  #echartsLine {
    width: 70%;
    margin: 50px 0 20px 50px;
    height: 400px;
  }
  .warning {
    color: #f00;
  }
  .breadcrumbStyle {
    color: #00000073;
    font-weight: 500;
  }
  .breadcrumbCursor {
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
}
</style>
